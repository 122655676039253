import * as React from 'react';
import SubscribeForm from '../components/SubscribeForm';
import * as styles from '../styles/HomePage.module.css';

export default function IndexPage() {

    return (
        <>
            <div className="container">
                <div>
                    <h1 className={styles.pageTitle}>Thread Syncer</h1>
                    <p className={styles.shortDescription}>Cross-platform conversation sync and backup solutions</p>
                </div>
                <SubscribeForm />
            </div>
        </>
    )
}